import React from "react";
import Card from "../card";
import { eventList } from "../helpers/eventList";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const upcomingEvents = eventList.filter(
    (event) => event.timestamp > new Date()
  );

  const pastEvents = eventList.filter((event) => event.timestamp < new Date());

  return (
    <div className="px-6 mb-8">
      <div className="header flex flex-wrap justify-center mt-4 items-center">
        <img
          src="/img/tibetanlogo.png"
          className="App-logo h-32 mb-6"
          alt="TCB North Logo"
        />
        <h1 className="text-center text-3xl text-white font-thin">
          Tibetan Community Britain North Association
        </h1>
      </div>

      <div className="grid grid-cols-3 gap-3 justify-center justify-items-center w-full text-white mt-6">
        <div>
          <a
            href="https://instagram.com/tibetancommunitynorthuk"
            target="_blank"
            rel="noreferrer"
            className="text-4xl"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/groups/171713289595372"
            target="_blank"
            rel="noreferrer"
            className="text-4xl"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
        <div>
          <a
            href="mailto:info@tcbnorth.co.uk"
            target="_blank"
            rel="noreferrer"
            className="text-4xl"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>

      <p className="text-white mt-6 text-xl font-bold">Upcoming Events</p>

      {upcomingEvents.map((event, index) => (
        <Card
          key={index}
          title={event.title}
          date={event.date}
          address={event.address}
          description={parse(event.description || "")}
          timestamp={event.timestamp}
        />
      ))}

      <p className="text-white mt-12 text-xl font-bold">Past Events</p>
      {pastEvents.map((event, index) => (
        <Card
          key={index}
          title={event.title}
          date={event.date}
          address={event.address}
          description={parse(event.description || "")}
          pastEvent={true}
        />
      ))}
    </div>
  );
};

export default Home;
