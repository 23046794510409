import React from "react";
import moment from "moment";
import { Div } from "./style";

const Card = ({ title, date, address, description, pastEvent, timestamp }) => {
  const currentDate = new Date();
  const eventDate = timestamp;

  const daysLeft = moment(eventDate).diff(currentDate, "days");

  return (
    <Div
      className={`card  rounded-lg p-6 shadow-md mt-4 ${
        pastEvent ? "bg-gray-50" : "bg-white"
      }`}
    >
      <h2 className="text-xl font-bold">{title}</h2>
      {date && (
        <p className="mt-4">
          <strong>Date: </strong>
          {date} {daysLeft > 0 && <>(In {daysLeft + 1} days)</>}
        </p>
      )}

      {address && (
        <p>
          <strong>Address: </strong>
          {address}
        </p>
      )}

      {description && <p className="mt-4">{description}</p>}
    </Div>
  );
};

export default Card;
