// src/news/NewsPage.js
import React from "react";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const newsItems = [
  {
    title: "Annual Summer Picnic: Unexpected Shutdown of Bowley Scout Camp",
    date: "2023-07-17",
    content: `
      <p>Dear TCB North members,</p><br/>
      <p>We have received some unexpected news that due to safety concerns, Bowley Scout Camp will need to shut down while they resolve issues on their site. This news was quite a shock to us, the committee, as we have had the venue booked since last year and the deposit paid.</p> </br>
      <p>We sent an email raising our concerns: that our community members have already arranged leave, made commitments to attend on this date, paid for their stay, and due to how last minute they have canceled, it has made it impossible to reschedule the event.</p> </br>
      <p>Unfortunately, despite our efforts, Bowley Scout Camp has informed us that they are unable to proceed with our booking due to safety considerations.</p> <br/>
      <p>We deeply regret any inconvenience this may cause and are currently discussing alternative venues and solutions. We will keep you updated with any new developments.</p> <br/>
      <p><a href="https://bowley.org.uk/shutdown/" class="text-blue-500 underline" target="_blank">Link provided from Bowley</a></p> <br/>
      <p>Our next steps:</p>
      <ul class="list-disc ml-6">
        <li>We will look for nearby venues which will accommodate our group in a similar setting</li>
        <li>We are in contact with Samye Ling Monastery about a potential stay</li>
        <li>It is important that we set a deadline so that our community members can arrange other plans should we not be able to find a suitable solution - we will inform the community by next Monday 22nd July.</li>
      </ul>
      <br/>
      <p>For those who have already made payment, we will of course refund the full amount in due course but if you require the amount back straight away do let us know.</p>
      <p>TCB-North Committee.</p>
    `,
  },
];

const NewsPage = () => {
  return (
    <div className="px-6 mb-8">
      <div
        className="header flex flex-wrap justify-center mt-4 items-center"
        onClick={() => (window.location.href = "/")}
      >
        <img
          src="/img/tibetanlogo.png"
          className="App-logo h-32 mb-6"
          alt="TCB North Logo"
        />
        <h1 className="text-center text-3xl text-white font-thin">
          Tibetan Community Britain North Association
        </h1>
      </div>

      <div className="grid grid-cols-3 gap-3 justify-center justify-items-center w-full text-white mt-6">
        <div>
          <a
            href="https://instagram.com/tibetancommunitynorthuk"
            target="_blank"
            rel="noreferrer"
            className="text-4xl"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/groups/171713289595372"
            target="_blank"
            rel="noreferrer"
            className="text-4xl"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
        <div>
          <a
            href="mailto:info@tcbnorth.co.uk"
            target="_blank"
            rel="noreferrer"
            className="text-4xl"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
      <div className="header flex flex-wrap justify-center mt-4 items-center">
        <h1 className="text-center text-3xl text-white font-thin">News</h1>
      </div>

      <div className="grid grid-cols-1 gap-6 justify-center justify-items-center w-full text-white mt-6">
        {newsItems.map((item, index) => (
          <div
            key={index}
            className="news-item bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-2xl"
          >
            <h2 className="text-2xl font-semibold mb-2">{item.title}</h2>
            <p className="text-sm text-gray-400 mb-4">
              <strong>Date:</strong> {item.date}
            </p>
            <div className="text-lg">{parse(item.content)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsPage;
